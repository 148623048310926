import React from 'react';
import { Helmet } from 'react-helmet';

import { Translate } from 'src/components/Translate';
import { useTracking } from 'src/components/TrackingContext';

const metadata = {
  title: 'Unauthorized Consent | HDC for State Farm',
};

const i18n = {
  title: 'state-farm.consent.unauthorized.title',
  description: 'state-farm.consent.unauthorized.description',
} as const;

const Unauthorized = () => {
  useTracking('sf_consent_unauthorized_view');

  return (
    <>
      <Helmet title={metadata.title} />
      <section className="state-farm-container">
        <div className="stack-l">
          <Translate as="h2" resourceKey={i18n.title} />
          <div className="white-space-pre-line">
            <Translate as="p" className="text-lead_2" resourceKey={i18n.description} useHtml />
          </div>
        </div>
      </section>
    </>
  );
};
export default Unauthorized;
